import axios from "axios";
import { accordionToggle } from "./components/accordion";
import { bannerSwiper } from "./components/banner-swiper";
import { getHeight } from "./components/big-photo-and-description-we-are-lexus";
import {
    handleBlogFilter,
    handleSiblingsFilter,
    handleTabs3,
} from "./components/blog";
import { handleFlexGrid, versionPrice } from "./components/box-with-background";
import { citiesList } from "./components/cities-list";
import { consultationBtnHandler, inquiryError } from "./components/consultation";
import { formDate } from "./components/date";
import { branchesList, driveBtnHandler, seriesListDT } from "./components/driving-test";
import { handleEgoSelects } from "./components/ego-select";
import {
    gallerySwiper,
    handleGalleryFilter,
    handleTabs,
} from "./components/gallery";
import {
    gallerySwiper2,
    handleGalleryWithTextFilter,
    handleTabs2,
} from "./components/gallery-with-text";
import {
    headerBehavior,
    languages,
    mobileMenuFunctionality,
} from "./components/header";
import { quotationBtnHandler, seriesList } from "./components/quotation";
import { handleVideo } from "./components/video";
import { initForms } from "./modules/forms";
import { docReady, getCookie, initializeTurnstile, hideSpinner } from "./modules/tools";
import { filterVersionsBySerie, maintenanceSwiper } from "./components/maintenance";

axios.defaults.headers.common["X-CSRFToken"] = getCookie("csrftoken");

docReady(() => {
    hideSpinner();
    headerBehavior();
    citiesList();
    mobileMenuFunctionality();
    handleEgoSelects();
    bannerSwiper();
    handleTabs();
    handleGalleryFilter();
    gallerySwiper();
    handleTabs2();
    handleGalleryWithTextFilter();
    gallerySwiper2();
    handleVideo();
    handleTabs3();
    handleBlogFilter();
    handleSiblingsFilter();
    accordionToggle();
    languages();
    initForms();
    seriesList();
    seriesListDT();
    branchesList();
    inquiryError();
    handleFlexGrid();
    formDate();
    versionPrice();
    consultationBtnHandler();
    driveBtnHandler();
    quotationBtnHandler();
    getHeight();
    initializeTurnstile('cf_turnstile', 'submitButton');
    maintenanceSwiper();
    filterVersionsBySerie();
});
