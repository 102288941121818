export const handleFlexGrid = () => {
    const box = document.getElementById("box-with-background");

    box?.childElementCount < 3 ? box?.classList.add("box-flex") : box?.classList.add("box-grid");
}

export const versionPrice = () => {
    let price = document.querySelectorAll(".box-with-background_card-price");
    price.forEach((item) => {
        const price = item.querySelector(".price");
        const bbonus = item.querySelector(".bbonus");
        const fbonus = item.querySelector(".fbonus");
        let showPrice = 0;

        if (bbonus && fbonus) {
            showPrice = parseFloat(price.innerText) - parseFloat(bbonus.innerText) - parseFloat(fbonus.innerText);
            price.innerText = showPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '*';
        } else {
            price? showPrice = parseFloat(price.innerText) : null;
            price? price.innerText = showPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : null;
        }
    })

    let price2 = document.querySelectorAll(".versions_item_text .price");
    price2.forEach((item) => {
        const price = item.querySelector(".price_span");
        const bbonus = item.querySelector(".bbonus");
        const fbonus = item.querySelector(".fbonus");
        let showPrice = 0;

        if (bbonus && fbonus) {
            showPrice = parseFloat(price.innerText) - parseFloat(bbonus.innerText) - parseFloat(fbonus.innerText);
        } else {
            showPrice = parseFloat(price.innerText);
        }

        price.innerText = showPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    })
}